export default {
  state: {
    user: localStorage.getItem('app.user') ? JSON.parse(localStorage.getItem('app.user')) : {},
    preloader: false,
    bootstrap: {},
    themeDark: localStorage.getItem('app.theme') || false,
  },
  getters: {
    getUser: function(state) {
      return state.user;
    },

    getTheme: function(state) {
      return state.themeDark;
    },

    getBootstrap: function(state) {
      return state.bootstrap;
    },

    getPreloader: function(state) {
      return state.preloader;
    }
  },
  mutations: {
    setTheme: function(state, payload) {
      localStorage.setItem('app.theme', payload);
      state.themeDark = payload;
    },

    setUser: function(state, payload) {
      state.user = payload;
    },

    setBootstrap: function(state, payload) {
      state.bootstrap = payload;
    },

    updateUser: function(state, payload) {
        state.user = {...state.user, ...payload};
    },

    togglePreloader: function(state, payload) {
      state.preloader = payload;
    }
  },
  actions: {
  },
  modules: {
  }
}
