var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"title":"Mining App Versions","items-per-page":500,"no-search-padding":"","search-by":[
      'Description',
      'Version' ],"show-filters":"","export-query-type":"workerMiningAppTypesExport","query-fields":[
      'ID',
      'MiningAppTypeID',
      'MiningAppType {Name}',
      'Description',
      'Version',
      'Config',
      'FileID',
      'IsActive',

      'CreatedAt',
      'UpdatedAt' ],"delete-mutation-type":"deleteWorkerMiningAppVersion","delete-mutation-field":"ids","delete-by":"ID","add-link":"worker_mining_app_versions/add","query-type":"workerMiningAppVersions","row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',[_vm._v("Mining App Type")]),_c('lth',{attrs:{"sort-by":"Version"}},[_vm._v("Version")]),_c('lth',{attrs:{"sort-by":"IsActive"}},[_vm._v("Is Active")]),_c('lth',{attrs:{"sort-by":"Description","width":"120"}},[_vm._v("Description")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Created At")]),_c('lth',{attrs:{"sort-by":"UpdatedAt","width":"150"}},[_vm._v("Updated At")])]},proxy:true},{key:"row",fn:function(ref){
    var item = ref.item;
return [_c('ltd',[_vm._v(_vm._s(item.MiningAppType ? item.MiningAppType.Name : ""))]),_c('ltd',[_vm._v(_vm._s(item.Version))]),_c('ltd',[_vm._v(_vm._s(item.IsActive))]),_c('ltd',[_vm._v(_vm._s(item.Description))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.UpdatedAt))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }