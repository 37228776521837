<template>
  <app-form
      :attachments="attachments"
      @save="save"

      :query-fields="[
          'ID',
          'MiningAppTypeID',
          'Description',
          'IsActive',
          'Version',
          'Config',
          'FileID',
          'File {ID Name Extension}',

          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',
          'MiningAppTypeID',
          'Description',
          'IsActive',
          'Config',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorkerMiningAppVersion"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorkerMiningAppVersion"
      query-type="workerMiningAppVersion"
      :title="`Mining App Version ${item.ID}`"
  >
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox
                v-model="item.IsActive"
                outlined
                label="Is Active"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkerMiningAppTypes"
                hide-details
                :items="workerMiningAppTypes"
                v-model="item.MiningAppTypeID"
                outlined
                label="Worker Mining App Type"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Description"
                hide-details
                type="text"
                outlined
                label="Description"
            />
          </v-col>

          <v-col cols="12">
            <app-file
                multiple
                v-model="attachments"
                :items="getCurrentFileItems"
                accept=".zip"
                outlined
                label="Attach Mining App"
            />
          </v-col>


          <v-col cols="12" lg="12">
            <v-textarea
                v-model="item.Config"
                hide-details
                outlined
                rows="15"
                label="Config"
            />
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    /*
    Ce tine de incarcarea fisierului:
     - trebuie sa incarcam doar zip-uri... necriptate... acesta va fi criptat pe partea de server?!
     -
     */

    return {
      item: {
        IsActive: false,
        MiningAppTypeID: null,
        Description: "",
        Config: null,

        FileID: null,
        File: {},
      },

      attachments: [],

      workerMiningAppTypes: [],
    }
  },

  computed: {
    getCurrentFileItems: {
      get() {
        if (this.item.FileID !== null) {
          // console.log("files ", this.item.FileID, [this.item.File]);
          return [this.item.File];
        }
        return [];
      },
    }
  },

  mounted() {
    this.getWorkerMiningAppTypes();
  },

  methods: {
    save: function () {
      this.attachments = [];
    },

    getWorkerMiningAppTypes: function () {
      this.getGQLItems('workerMiningAppTypes', [
        'Name',
        'ID',
      ]).then(res => {
        this.workerMiningAppTypes = res.data.workerMiningAppTypes.Items;
      });
    },
  }
}
</script>