<template>
  <th @click="sort">
    <div v-on:mouseover="onHover"
         v-on:mouseleave="onHover(false)"
         :class="{
          'app-list-th': true,
          'sort': !!sortBy,
          'theme--dark': this.getTheme
          }">

      <template v-if="getValue">
        {{ getValue }}
      </template>
      <template v-else>
        <slot></slot>
      </template>
        <v-icon v-if="sortBy && !direction" x-small :style="`opacity: ${hover && !direction ? '0.5' : '0'}`">
            north
        </v-icon>

        <v-icon v-if="sortBy && direction" x-small>
          {{ direction === 'asc' ? 'north' : 'south' }}
        </v-icon>
    </div>
  </th>
</template>
<script>
export default {

  data() {
    return {
      direction: '',
      hover: false,
      timer: null,
    }
  },

  props: {
    sortBy: String,
    amount: Boolean,
  },

  watch: {
    getListSort: {
      handler(sort) {
        if (this.sortBy !== sort.field) {
          this.direction = null;
        } else {
          this.direction = sort.direction;
        }
      },
      deep: true,
    }
  },

  mounted() {
    if (this.getListSort.field === this.sortBy) {
      this.direction = this.getListSort.direction;
    }
  },

  methods: {
    onHover: function (value) {
      if (this.sortBy) {
        if (value) {
          this.hover = true;
        } else {
          this.hover = false;
        }
      }
    },

    sort: function () {
      window.clearTimeout(this.timer);
      if (!this.sortBy) {
        return false;
      }

      if (!this.direction) {
        this.direction = 'asc';
      } else if (this.direction === 'asc') {
        this.direction = 'desc';
      } else if (this.direction === 'desc') {
        this.direction = null;
      }

      let sortBy = this.sortBy;

      if (!this.direction) {
        this.direction = '';
        sortBy = '';
      }
      this.timer = setTimeout(() => {
        this.$store.commit('setListSort', {
          direction: this.direction,
          field: sortBy
        });
      }, 350);
    }
  },

  computed: {
    getListSort: function () {
      return this.$store.getters.getListSort;
    },

    getValue: function () {
      if (this.amount) {
        return this.formatAmount(this.getContent);
      }

      return null;
    },

    getContent: function () {
      if (this.$slots.default) {
        return this.$slots.default[0].text;
      }
      return '';
    },

  }
}
</script>
<style lang="scss" scoped>
.sort {
  cursor: pointer;

  &:hover {
    &.theme--dark {
      color: #fff;
    }

    color: #000;
  }
}
</style>