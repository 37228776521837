export default {

    methods: {
        routerLoad: function () {
            this.onEnterRoute(this.$route, () => {
            });
        },

        replaceBreadcrumbsLink: function (href) {
            let params = this.$route.params;

            Object.keys(params).forEach(param => {
                let value = params[param];
                let pattern = new RegExp(`:${param}`, 'g');
                if (href.search(pattern) >= 0) {
                    href =  href.replace(pattern, value);
                }
            });
            return href;
        },

        routerBeforeEach: function (router) {
            router.beforeEach((to, from, next) => {
                this.onEnterRoute(to, () => {
                    next();
                });
            });
        },

        onEnterRoute: function (route, callbackSuccess) {
            let isAuthorized = this.isAuthorized;
            let direction = route.path;

            if (!isAuthorized && direction !== '/login') {
                this.goToLogin();
                return false;
            }

            if (isAuthorized) {
                // let getLoginRoute = '/login';
                let getLoginRoute = '/login';
                // if accessed login page
                if (direction === getLoginRoute || direction === '/') {
                    this.goToPage('/');
                } else {
                    // if not
                    if (this.isRoleRequired(route)) {
                        callbackSuccess();
                    } else {
                        this.goToPage('/');
                    }
                }
            } else if (!isAuthorized && this.isAuthRequired(route)) {
              this.goToLogin();
            } else {
                callbackSuccess();
            }
        },

        isAuthRequired: function (route) {
            let enteredRoute = route.matched[0];
            if (enteredRoute.meta) {
                return (enteredRoute.meta.requiresAuth);
            }
            return false;
        },

        isRoleRequired: function (route) {
            let enteredRoute = route.matched[0];
            if (route.meta && route.meta.skipRoleMiddleware) {
                return true;
            }
            // if does not require module
            if (enteredRoute.meta.requiresRole) {
                if (this.getRole !== enteredRoute.meta.requiresRole) {
                    return false;
                }
            }

            return true;
        }
    },

    computed: {},

};