import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery';
import AdminWebMain from '../../views/view_modules/admin/app/web/Main';
import AdminMobMain from '../../views/view_modules/admin/app/mob/Main';

import ManagerWebMain from '../../views/view_modules/manager/app/web/Main';
import ManagerMobMain from '../../views/view_modules/manager/app/mob/Main';

import FourOFour from '../../views/404/FourOFour';
import SignIn from '../../views/auth/SignIn';

import Users from '../../views/view_modules/admin/users/Index';
import UsersForm from '../../views/view_modules/admin/users/Form';

// ====================================== \\

import Workers from '../../views/view_modules/admin/worker/Index';
import WorkerForm from '../../views/view_modules/admin/worker/Form';

import WorkerPlatforms from '../../views/view_modules/admin/worker_platform/Index';
import WorkerPlatformForm from '../../views/view_modules/admin/worker_platform/Form';

import WorkerSchedulers from '../../views/view_modules/admin/worker_scheduler/Index';
import WorkerSchedulerForm from '../../views/view_modules/admin/worker_scheduler/Form';

import WorkerMiningAppTypes from '../../views/view_modules/admin/worker_mining_app_type/Index';
import WorkerMiningAppTypeForm from '../../views/view_modules/admin/worker_mining_app_type/Form';

import WorkerMiningAppVersions from '../../views/view_modules/admin/worker_mining_app_version/Index';
import WorkerMiningAppVersionForm from '../../views/view_modules/admin/worker_mining_app_version/Form';

import WorkerAppVersions from '../../views/view_modules/admin/worker_app_version/Index';
import WorkerAppVersionForm from '../../views/view_modules/admin/worker_app_version/Form';


import WorkerMiningConfigs from '../../views/view_modules/admin/worker_mining_config/Index';
import WorkerMiningConfigForm from '../../views/view_modules/admin/worker_mining_config/Form';


// ====================================== \\


Vue.use(VueRouter);

let adminMainComponent = window.innerWidth < 600 ? AdminMobMain : AdminWebMain;
let managerMainComponent = window.innerWidth < 600 ? ManagerMobMain : ManagerWebMain;

let dashboardBC = [{text: 'Dashboard', href: '/',}];
let listBC = [...dashboardBC, {text: 'Nomenclator'}];

const routes = [
    {path: '*', component: FourOFour},
    {path: '/login', component: SignIn, name: 'login'},
    {
        path: '/sys/user/admin',
        component: adminMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'admin'
        },
        children: [
            // ===================== Worker ===========================\\

            {
                path: 'workers', component: Workers,
                meta: {breadcrumbs: [...listBC, {text: 'Workers'}]}
            },
            {
                path: 'workers/edit/:id', component: WorkerForm,
                meta: {breadcrumbs: [...listBC, {text: 'Workers', href: 'workers'}, {text: 'Edit Worker'}]}
            },
            {
                path: 'workers/add', component: WorkerForm,
                meta: {breadcrumbs: [...listBC, {text: 'Workers', href: 'workers'}, {text: 'Add Worker'}]}
            },
            // ===================== Worker ===========================\\


            //

            // ===================== Worker Platform ===========================\\
            {
                path: 'worker_platforms', component: WorkerPlatforms,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerPlatforms'}]}
            },
            {
                path: 'worker_platforms/edit/:id', component: WorkerPlatformForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Workers',
                        href: 'worker_platforms'
                    }, {text: 'Edit Worker Platform'}]
                }
            },
            {
                path: 'worker_platforms/add', component: WorkerPlatformForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Workers',
                        href: 'worker_platforms'
                    }, {text: 'Add Worker Platform'}]
                }
            },
            // ===================== Worker Platform ===========================\\

            //

            // ===================== Worker Schedulers ===========================\\
            {
                path: 'worker_schedulers', component: WorkerSchedulers,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerSchedulers'}]}
            },
            {
                path: 'worker_schedulers/edit/:id', component: WorkerSchedulerForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Schedulers',
                        href: 'worker_schedulers'
                    }, {text: 'Edit Worker Scheduler'}]
                }
            },
            {
                path: 'worker_schedulers/add', component: WorkerSchedulerForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Worker Schedulers',
                        href: 'worker_schedulers'
                    }, {text: 'Add Worker Scheduler'}]
                }
            },
            // ===================== Worker Schedulers ===========================\\

            //

            // ===================== Worker Mining App Types ===========================\\
            {
                path: 'worker_mining_app_types', component: WorkerMiningAppTypes,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerMiningAppTypes'}]}
            },
            {
                path: 'worker_mining_app_types/edit/:id', component: WorkerMiningAppTypeForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Types',
                        href: 'worker_mining_app_types'
                    }, {text: 'Edit Mining App Type'}]
                }
            },
            {
                path: 'worker_mining_app_types/add', component: WorkerMiningAppTypeForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Types',
                        href: 'worker_mining_app_types'
                    }, {text: 'Add Mining App Type'}]
                }
            },
            // ===================== Worker Mining App Types ===========================\\

            //

            // ===================== Worker Mining App Versions ===========================\\
            {
                path: 'worker_mining_app_versions', component: WorkerMiningAppVersions,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerMiningAppVersions'}]}
            },
            {
                path: 'worker_mining_app_versions/edit/:id', component: WorkerMiningAppVersionForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Versions',
                        href: 'worker_mining_app_versions'
                    }, {text: 'Edit Mining App Version'}]
                }
            },
            {
                path: 'worker_mining_app_versions/add', component: WorkerMiningAppVersionForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Versions',
                        href: 'worker_mining_app_versions'
                    }, {text: 'Add Mining App Version'}]
                }
            },
            // ===================== Worker Mining App Versions ===========================\\

            //

            // ===================== Worker Mining Configs ===========================\\
            {
                path: 'worker_mining_configs', component: WorkerMiningConfigs,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerMiningConfigs'}]}
            },
            {
                path: 'worker_mining_configs/edit/:id', component: WorkerMiningConfigForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Configs',
                        href: 'worker_mining_configs'
                    }, {text: 'Edit Mining Config'}]
                }
            },
            {
                path: 'worker_mining_configs/add', component: WorkerMiningConfigForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Configs',
                        href: 'worker_mining_configs'
                    }, {text: 'Add Mining Config'}]
                }
            },
            // ===================== Worker Mining Configs ===========================\\

            //

            // ===================== Worker App Versions ===========================\\
            {
                path: 'worker_app_versions', component: WorkerAppVersions,
                meta: {breadcrumbs: [...listBC, {text: 'WorkerAppVersions'}]}
            },
            {
                path: 'worker_app_versions/edit/:id', component: WorkerAppVersionForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'Mining App Versions',
                        href: 'worker_app_versions'
                    }, {text: 'Edit App Version'}]
                }
            },
            {
                path: 'worker_app_versions/add', component: WorkerAppVersionForm,
                meta: {
                    breadcrumbs: [...listBC, {
                        text: 'App Versions',
                        href: 'worker_app_versions'
                    }, {text: 'Add App Version'}]
                }
            },
            // ===================== Worker App Versions ===========================\\


            //

            // ====================== Users =====================\\

            {
                path: 'users', component: Users,
                meta: {breadcrumbs: [...dashboardBC, {text: 'Utilizatori'}]}
            },
            {
                path: 'users/edit/:id', component: UsersForm,
                meta: {breadcrumbs: [...listBC, {text: 'Utilizatori', href: 'users'}, {text: 'Editează utilizator'}]}
            },
            {
                path: 'users/add', component: UsersForm,
                meta: {breadcrumbs: [...listBC, {text: 'Utilizatori', href: 'users'}, {text: 'Adaugă utilizator'}]}
            },
            // ====================== Users =====================\\

            //
        ]
    },
    {
        path: '/sys/user/manager',
        component: managerMainComponent,
        meta: {
            requiresAuth: true,
            requiresRole: 'manager'
        },
        children: []
    },
]

const router = new VueRouter({
    // base: (process.env.NODE_ENV === 'production') ? "/v1" : "/",
    mode: 'hash',
    routes
})

export default router
