<template>
  <app-form
      :query-fields="[
          'ID',
          'Name',
          'Description',

          'MiningAppTypeID',
          'MiningAppVersionID',

          'Config',

          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',
          'Name',
          'Description',
          'MiningAppTypeID',
          'MiningAppVersionID',
          'Config',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorkerMiningConfig"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorkerMiningConfig"
      query-type="workerMiningConfig"
      :title="`Mining App Config ${item.ID}`"
  >
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>

          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Name"
                hide-details
                type="text"
                outlined
                label="Name"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkerMiningAppTypes"
                hide-details
                :items="workerMiningAppTypes"
                v-model="item.MiningAppTypeID"
                outlined
                label="Worker Mining App Type"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Description"
                item-value="ID"
                @change="getWorkerMiningAppVersions"
                hide-details
                :items="workerMiningAppVersions"
                v-model="item.MiningAppVersionID"
                outlined
                label="Worker Mining App Version"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Description"
                hide-details
                type="text"
                outlined
                label="Description"
            />
          </v-col>

          <v-col cols="12" lg="12">
            <v-textarea
                v-model="Config"
                hide-details
                outlined
                label="Config"
                rows="15"
            />
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {
        Name: "",
        Description: "",
        Config: null,

        MiningAppTypeID: null,
        MiningAppVersionID: null,
      },
      workerMiningAppTypes: [],
      workerMiningAppVersions: [],
      workerMiningAppVersionsIndexed: {},
    }
  },

  computed: {
    Config: {
      get() {
        if (!this.item.Config && this.item.MiningAppVersionID) {
          // Get the config from the current mining app version

          if (this.workerMiningAppVersionsIndexed.hasOwnProperty(this.item.MiningAppVersionID)) {
            return this.workerMiningAppVersionsIndexed[this.item.MiningAppVersionID].Config;
          }
        }
        return this.item.Config;
      },
      set(value) {
        this.item.Config = value;
      },
    }
  },

  mounted() {
    this.getWorkerMiningAppTypes();
    this.getWorkerMiningAppVersions();
  },

  methods: {
    getWorkerMiningAppTypes: function () {
      this.getGQLItems('workerMiningAppTypes', [
        'Name',
        'ID',
      ]).then(res => {
        this.workerMiningAppTypes = res.data.workerMiningAppTypes.Items;
      });
    },

    getWorkerMiningAppVersions: function () {
      this.getGQLItems('workerMiningAppVersions', [
        'Description',
        'Config',
        'ID',
      ]).then(res => {
        this.workerMiningAppVersions = res.data.workerMiningAppVersions.Items;
        if (res.data.workerMiningAppVersions.Items.length > 0) {
          let indexedItems = {};
          res.data.workerMiningAppVersions.Items.map((el) => {
            indexedItems[el.ID] = el;
          });
          this.workerMiningAppVersionsIndexed = indexedItems;
        }
      });
    },
  }
}
</script>