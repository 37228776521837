<template>
  <app-form
      :query-fields="[
          'ID',
          'WorkerID',
          'Worker {Name}',

          'MiningConfigID',

          'From',
          'To',

          'AllDay',
          'OnAlways',
          'OnMonday',
          'OnTuesday',
          'OnWednesday',
          'OnThursday',
          'OnFriday',
          'OnSaturday',
          'OnSunday',

          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',

          'WorkerID',
          'MiningConfigID',

          'From',
          'To',

          'AllDay',
          'OnAlways',

          'OnMonday',
          'OnTuesday',
          'OnWednesday',
          'OnThursday',
          'OnFriday',
          'OnSaturday',
          'OnSunday',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorkerScheduler"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorkerScheduler"
      query-type="workerScheduler"
      :title="`Scheduler ${item.ID}`">
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col>
            Current time in UTC is: {{ getNowUtc }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkers"
                hide-details
                :items="workers"
                v-model="item.WorkerID"
                outlined
                label="Worker"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkerMiningConfigs"
                hide-details
                :items="workerMiningConfigs"
                v-model="item.MiningConfigID"
                outlined
                label="Worker Mining Config"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <timepicker
                hide-details
                v-model="item.From"
                outlined
                label="From (UTC)"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <timepicker
                hide-details
                v-model="item.To"
                outlined
                label="To (UTC)"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.AllDay"
                hide-details
                outlined
                label="All Day"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnMonday"
                hide-details
                outlined
                label="On Monday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnTuesday"
                hide-details
                outlined
                label="On Tuesday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnWednesday"
                hide-details
                outlined
                label="On Wednesday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnThursday"
                hide-details
                outlined
                label="On Thursday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnFriday"
                hide-details
                outlined
                label="On Friday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnSaturday"
                hide-details
                outlined
                label="On Saturday"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.OnSunday"
                hide-details
                outlined
                label="On Sunday"
            />
          </v-col>

        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>

import TimePicker from "./Time";

export default {
  components: {
    "timepicker": TimePicker,
  },

  data() {
    let _this = this;

    // console.log("moment now",_this.$moment().format('YYYY-MM-DD'))

    return {
      item: {
        // TODO: LATER de scris lui Alin
        // From: _this.$moment().format('YYYY-MM-DD'),
        // To: _this.$moment().format('YYYY-MM-DD'),
        From: null,
        To: null,

        WorkerID: null,
        MiningConfigID: null,


        AllDay: false,
        OnAlways: false,

        OnMonday: false,
        OnTuesday: false,
        OnWednesday: false,
        OnThursday: false,
        OnFriday: false,
        OnSaturday: false,
        OnSunday: false,
      },
      workers: [],
      workerMiningConfigs: [],
    }
  },

  mounted() {
    this.getWorkers();
    this.getWorkerMiningConfigs();
  },

  computed: {
    getNowUtc: function () {
      return this.$moment().utc().format("YYYY-MM-DD HH:mm")
    },
  },

  methods: {
    getWorkerMiningConfigs: function () {
      this.getGQLItems('workerMiningConfigs', [
        'Name',
        'ID',
      ]).then(res => {
        this.workerMiningConfigs = res.data.workerMiningConfigs.Items;
      });
    },

    getWorkers: function () {
      this.getGQLItems('workers', [
        'Name',
        'ID',
      ]).then(res => {
        this.workers = res.data.workers.Items;
      });
    },
  }
}
</script>