<template>
  <div>
    <template v-if="text">
      <v-text-field clearable :label="label" :dense="getListDense" flat filled outlined hide-details
                    v-model="fieldValue"/>
    </template>
    <template v-else-if="select || multiselect">
      <v-autocomplete clearable :item-text="itemText" :item-value="itemValue"
                      :label="label"
                      :dense="getListDense"
                      :multiple="multiselect"
                      flat filled outlined hide-details
                      v-model="fieldValue" :items="items"/>
    </template>
    <template v-else-if="date">
      <Date :modal="modal" :dense="getListDense" outlined filled :label="`${label}`"
            v-model="fieldValue"/>
    </template>
  </div>
</template>
<script>
import Date from "@/components/page/list/components/Date";

export default {
  components: {Date},
  data() {
    return {
      timer: null,

      fieldValue: this.value,
      range: false,
    }
  },


  watch: {
    getListFilters: function (filters) {
      filters.forEach(filter => {
        if (filter.field === this.field && filter.type === this.getFilterType) {
          this.fieldValue = filter.value;
        }
      });
    },

    fieldValue: function (value) {
      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('input', value);
        this.$emit('change', value);

        if (!this.field) {
          return false;
        }
        if (!this.independent) {
          this.$store.commit('filterListBy', {
            field: this.field,
            value: this.fieldValue,
            type: this.getFilterType,
            filterInputName: this.filterInputName
          });
        }
      }, 350);
    },

    value: function (value) {
      this.fieldValue = value;
    }
  },

  props: {
    itemText: {
      default() {
        return 'Name'
      }
    },
    itemValue: {
      default() {
        return 'ID'
      }
    },

    independent: Boolean,
    text: Boolean,
    select: Boolean,
    multiselect: Boolean,
    modal: Boolean,
    date: Boolean,
    field: String,
    label: String,
    filterInputName: {
      default() {
        return 'default'
      }
    },
    items: Array,
    value: {
      default() {
        return null;
      }
    }
  },

  computed: {
    getListFilters: function () {
      return this.$store.getters.getListFilters;
    },

    getListDense: function () {
      return this.$parent.actions.dense;
    },

    getFilterType: function () {
      if (this.text) {
        return 'text';
      } else if (this.select) {
        return 'select';
      } else if (this.date) {
        return 'date';
      } else if (this.multiselect) {
        return 'multiselect';
      } else {
        return 'text';
      }
    }
  }
}
</script>