var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"title":"Utilizatori","search-by":['FirstName', 'LastName', 'PrimaryPhone', 'PrimaryEmail'],"show-filters":"","query-fields":[
              'ID',
              'FirstName',
              'LastName',
              'PrimaryPhone',
              'PrimaryEmail',
              'Role',
              'CreatedAt'
          ],"delete-mutation-type":"deleteUser","delete-mutation-field":"userIDs","delete-by":"ID","query-type":"users","add-link":"users/add","hide-filters":""},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',[_vm._v("Nume")]),_c('lth',[_vm._v("Telefon")]),_c('lth',[_vm._v("Email")]),_c('lth',[_vm._v("Rol")]),_c('lth',[_vm._v("Data creării")])]},proxy:true},{key:"row",fn:function(ref){
          var item = ref.item;
return [_c('ltd',{attrs:{"link":("users/edit/" + (item.ID))}},[_vm._v(_vm._s(item.FirstName)+" "+_vm._s(item.LastName))]),_c('ltd',[_vm._v(_vm._s(item.PrimaryPhone))]),_c('ltd',[_vm._v(_vm._s(item.PrimaryEmail))]),_c('ltd',[_vm._v(_vm._s(item.Role))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }