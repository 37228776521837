var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-form',{attrs:{"query-fields":[
        'ID',
        'Name',
        'CurrentVersion',
        'CreatedAt',
        'UpdatedAt' ],"mutation-fields":[
        'ID',
        'Name' ],"delete-mutation-type":"deleteWorkerMiningAppType","route-param":"id","delete-field":"ids","mutation-type":"saveWorkerMiningAppType","query-type":"workerMiningAppType","title":("Mining App Type " + (_vm.item.Name) + " / " + (_vm.item.ID))},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"text","outlined":"","label":"Name"},model:{value:(_vm.item.Name),callback:function ($$v) {_vm.$set(_vm.item, "Name", _vm._n($$v))},expression:"item.Name"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }