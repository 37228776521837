<template>
  <div :class="{'d-flex': true, 'wrap': mobile, 'flex-column': !mobile}">
    <v-tooltip :left="!mobile" :top="mobile">
      <template v-slot:activator="{on}">
        <v-btn @click="$emit('remove')"
               v-on="on"
               v-if="displayDeleteButton" icon>
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
      <span>Șterge</span>
    </v-tooltip>

    <v-tooltip :left="!mobile" :top="mobile">
      <template v-slot:activator="{on}">
        <v-btn v-if="!hideBackButton" v-on="on" @click="$router.back()" icon>
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
      <span>Înapoi</span>
    </v-tooltip>

    <v-tooltip :left="!mobile" :top="mobile">
      <template v-slot:activator="{on}">
        <v-btn v-if="!hideSaveButton" v-on="on" @click="$emit('save', true)" :loading="getPreloader" icon>
          <v-icon>check</v-icon>
        </v-btn>
      </template>
      <span>Salvează și Închide</span>
    </v-tooltip>

    <v-tooltip :left="!mobile" :top="mobile">
      <template v-slot:activator="{on}">
        <v-btn v-if="!hideSaveButton" v-on="on" @click="$emit('save', false)" :loading="getPreloader" icon>
          <v-icon>save</v-icon>
        </v-btn>
      </template>
      <span>Salvează</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    displaySaveButton: Boolean,
    hideSaveButton: Boolean,
    displayDeleteButton: Boolean,
    hideBackButton: Boolean,
    formValidation: Boolean,
    mobile: Boolean,
  },

  mounted() {
    if (!this.mobile) {
      this.$nextTick(() => {
        this.resize();
      });
      $(window).on('resize', () => {
        this.resize();
      });
    }
  },

  methods: {
    resize: function() {
      let el = $(this.$parent.$parent.$el);
      let contentEl = el.find('.app-form-section');
      let actionsEl = $('.app-form-actions');
      let offsetLeft = el.offset().left;

      let left = offsetLeft + el.width() + 1;
      let top;
      let contentHeight = contentEl.offset().top + contentEl.height();
      let windowHeight = $(window).height() - this.$vuetify.application.top;
      let topContent = contentEl.offset().top;
      let topWindow = windowHeight / 2.3;

      if (contentHeight > windowHeight) {
        top = topWindow;
      } else {
        top = topContent;
      }

      actionsEl.css('left', `${left}px`);
      actionsEl.css('top', `${top}px`);
    }
  }
}
</script>