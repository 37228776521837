<template>
  <app-form
      :query-fields="[
          'ID',
          'Name',
          'CurrentVersion',
          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',
          'Name',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorkerMiningAppType"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorkerMiningAppType"
      query-type="workerMiningAppType"
      :title="`Mining App Type ${item.Name} / ${item.ID}`"
  >
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Name"
                hide-details
                type="text"
                outlined
                label="Name"
            />
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {
        Name: "",
      },
    }
  },

  mounted() {

  },

  methods: {}
}
</script>