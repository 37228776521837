<template>
  <app-list
      title="Worker App Versions"
      :items-per-page="500"
      no-search-padding
      :search-by="[
        'Description',
        'Version',
      ]"
      show-filters
      export-query-type="workerAppTypesExport"
      :query-fields="[
        'ID',
        'PlatformID',
        'Platform {Name}',
        'Description',
        'Version',
        'FileID',
        'IsActive',

        'CreatedAt',
        'UpdatedAt',
      ]"
      delete-mutation-type="deleteWorkerAppVersion"
      delete-mutation-field="ids"
      delete-by="ID"
      add-link="worker_app_versions/add"
      query-type="workerAppVersions"
      :row-click="onRowClick"
  >
    <template #head>
      <lth>Platform Type</lth>
      <lth sort-by="Version">Version</lth>
      <lth sort-by="IsActive">Is Active</lth>
      <lth sort-by="Description" width="120">Description</lth>
      <lth sort-by="CreatedAt">Created At</lth>
      <lth sort-by="UpdatedAt" width="150">Updated At</lth>
    </template>
    <template #row="{item}">
      <ltd>{{ item.Platform ? item.Platform.Name : "" }}</ltd>
      <ltd>{{ item.Version }}</ltd>
      <ltd>{{ item.IsActive }}</ltd>
      <ltd>{{ item.Description }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  methods: {
    onRowClick: function (item) {
      this.goToPage(`worker_app_versions/edit/${item.ID}`);
    },
  },

  data: () => ({}),

  mounted() {

  }
}
</script>