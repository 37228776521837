<template>
  <app-form
      :attachments="attachments"
      @save="save"

      :query-fields="[
          'ID',
          'PlatformID',
          'Description',
          'IsActive',
          'Version',
          'FileID',
          'File {ID Name Extension}',

          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',
          'PlatformID',
          'Description',
          'IsActive',
          'Version',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorkerAppVersion"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorkerAppVersion"
      query-type="workerAppVersion"
      :title="`App Version ${item.ID}`"
  >
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-checkbox
                v-model="item.IsActive"
                outlined
                label="Is Active"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkerPlatforms"
                hide-details
                :items="workerPlatforms"
                v-model="item.PlatformID"
                outlined
                label="Platform"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Description"
                hide-details
                type="text"
                outlined
                label="Description"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Version"
                hide-details
                type="number"
                outlined
                label="Version"
            />
          </v-col>

          <v-col cols="12">
            <app-file
                multiple
                v-model="attachments"
                :items="getCurrentFileItems"
                accept=".zip"
                outlined
                label="Attach Mining App"
            />
          </v-col>

        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    /*
    Ce tine de incarcarea fisierului:
     - trebuie sa incarcam doar zip-uri... necriptate... acesta va fi criptat pe partea de server?!
     -
     */

    return {
      item: {
        IsActive: false,
        PlatformID: null,
        Description: "",
        Version: 0,
        FileID: null,
        File: {},
      },

      attachments: [],
      workerPlatforms: [],
    }
  },

  computed: {
    getCurrentFileItems: {
      get() {
        if (this.item.FileID !== null) {
          // console.log("files ", this.item.FileID, [this.item.File]);
          return [this.item.File];
        }
        return [];
      },
    }
  },

  mounted() {
    this.getWorkerPlatforms();
  },

  methods: {
    save: function () {
      this.attachments = [];
    },

    getWorkerPlatforms: function () {
      // let query = this.getSingleQuery('VendorID', value);

      this.getGQLItems('workerPlatforms', [
        'Name',
        'ID',
      ]).then(res => {
        this.workerPlatforms = res.data.workerPlatforms.Items;
      });
    },

  }
}
</script>