var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"title":"Worker Platforms","items-per-page":500,"no-search-padding":"","search-by":[
      'Name' ],"show-filters":"","export-query-type":"workerPlatformsExport","query-fields":[
      'ID',
      'Name',
      'CreatedAt',
      'UpdatedAt' ],"delete-mutation-type":"deleteWorkerPlatform","delete-mutation-field":"ids","delete-by":"ID","add-link":"worker_platforms/add","query-type":"workerPlatforms","row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name","width":"120"}},[_vm._v("Name")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Created At")]),_c('lth',{attrs:{"sort-by":"UpdatedAt","width":"150"}},[_vm._v("Updated At")])]},proxy:true},{key:"row",fn:function(ref){
    var item = ref.item;
return [_c('ltd',[_vm._v(_vm._s(item.Name))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.UpdatedAt))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }