<template>
  <div class="d-flex flex-column">
    <v-app-bar
        class="v-bar--underline"
        :color="!getTheme ? 'darken-2' : ''"
        elevation="0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Crypto Manager</v-toolbar-title>

      <v-spacer/>
      <v-btn @click="setTheme" icon>
        <v-icon>{{ getTheme ? 'wb_sunny' : 'dark_mode' }}</v-icon>
      </v-btn>

      <v-btn @click="signOut" icon>
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
        width="300"
        v-model="drawer"
        fixed>
      <Profile/>
      <List :items="items"/>
    </v-navigation-drawer>
    <v-container>
      <router-view></router-view>
    </v-container>
  </div>
</template>
<script>
import List from '@/components/app/menu/List';
import Profile from "@/components/app/menu/Profile";

export default {
  components: {
    Profile,
    List
  },

  data() {
    return {
      drawer: false,
      items: [
        {title: 'Main Page', icon: 'grid_view', link: '/'},

        {
          title: 'Management',
          icon: 'ballot',
          items: [
            {title: 'Schedulers', link: 'worker_schedulers'},
            {title: 'Workers', link: 'workers'},
            {title: 'Mining Configs', link: 'worker_mining_configs'},
            {title: 'Mining App Versions', link: 'worker_mining_app_versions'},
            {title: 'Mining App Types', link: 'worker_mining_app_types'},
            {title: 'Platforms', link: 'worker_platforms'},
            {title: 'Worker App Versions', link: 'worker_app_versions'},
            {divider: true},
          ]
        },

        {
          title: 'Rapoarte',
          icon: 'tab',
          items: []
        },

        {title: 'Users', icon: 'manage_accounts', link: 'users'}
      ]
    }
  }
}
</script>