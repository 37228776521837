import app from './app/store'
import list from './list/store'
import Vuex from 'vuex';
import Vue from "vue";
let centralStore = {
    modules: {
        app: app,
        list: list,
    }
};

Vue.use(Vuex);
export default new Vuex.Store(centralStore);