<template>
  <v-form @submit.prevent="signIn">
    <v-card max-width="500" class="app-auth-section" elevation="4" tag="section">
      <v-card-title>
        <v-layout align-center justify-space-between>
          <Logo/>
          <v-spacer/>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>Aurotizați-vă cu numele de utilizator și parola:</p>
        <v-text-field
            prepend-icon="person"
            label="Username"
            type="text"
            v-model="username"></v-text-field>
        <v-text-field
            prepend-icon="lock"
            hide-details
            label="Password"
            type="password"
            v-model="password"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
        <v-btn block depressed
               :loading="getPreloader"
               color="info"
               type="submit"
               :large="$vuetify.breakpoint.smAndUp">
          <v-icon left>login</v-icon>
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Logo from "@/components/logo/Logo";
import gql from 'graphql-tag';

export default {
  components: {Logo},
  data() {
    return {
      username: '',
      password: '',
    }
  },

  methods: {
    signIn: function () {
      this.togglePreloader();

      this.$apollo.mutate({
        // Query
        mutation: gql`mutation ($username: String!, $password: String!) {
          usernameAuth (input: {username: $username, password: $password}) {
            token
            userId
            expireAt
            ttl
          }
        }`,

        client: 'a',
        // Parameters
        variables: {
          password: this.password,
          username: this.username,
        },
      }).then(auth => {
        this.setUser({
          id: auth.data.usernameAuth.userId,
          token: auth.data.usernameAuth.token,
        });

        let me = this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`query {
            me {
                FirstName
                LastName
                UserName
                LocationID
                PrimaryEmail
                CreatedAt
                Role
              }
          }`
        }).then(me => {
          this.updateUser(me.data.me);
        });
        Promise.all([me]).then(() => {
          localStorage.setItem('app.user', JSON.stringify(this.getUser));
          this.goToPage('/');
          this.notify('Autorizare cu succes');
          this.togglePreloader(false);
        }).catch(res => {
          this.notify('Datele despre utilizator nu au fost primite cu succes', 'error');
          this.togglePreloader(false);
        });
      }).catch(res => {
        this.togglePreloader(false);
        this.notify('Eroare autorizare, username-ul sau parola este greșit', 'error');
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.app-auth-section {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 25px;
}
</style>