var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"title":"Workers","items-per-page":500,"no-search-padding":"","search-by":[
        'Name',
        'AuthToken' ],"show-filters":"","export-query-type":"workersExport","query-fields":[
        'ID',
        'Name',
        'Description',
        'PlatformID',
        'Platform {Name}',
        'OsVersion',
        'AuthToken',
        'SchedulerUpdatedAt',
        'IsActive',
        'IsSelfDestructOn',
        'CreatedAt',
        'UpdatedAt',

        'IsOnline',
        'WsConnectedAt',
        'WsDisconnectedAt' ],"delete-mutation-type":"deleteWorker","delete-mutation-field":"ids","delete-by":"ID","add-link":"workers/add","query-type":"workers","row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"Name","width":"120"}},[_vm._v("Name")]),_c('lth',{attrs:{"sort-by":"IsOnline"}},[_vm._v("Is Online")]),_c('lth',{attrs:{"sort-by":"WsConnectedAt"}},[_vm._v("Connected At")]),_c('lth',{attrs:{"sort-by":"WsDisconnectedAt"}},[_vm._v("Disconnected At")]),_c('lth',{attrs:{"sort-by":"PlatformID","width":"120"}},[_vm._v("Platform")]),_c('lth',{attrs:{"sort-by":"OsVersion"}},[_vm._v("Os Version")]),_c('lth',{attrs:{"sort-by":"Description"}},[_vm._v("Description")]),_c('lth',{attrs:{"sort-by":"IsActive"}},[_vm._v("Is Active")]),_c('lth',{attrs:{"sort-by":"IsSelfDestructOn"}},[_vm._v("Is Self Destruct On")]),_c('lth',{attrs:{"sort-by":"SchedulerUpdatedAt","width":"170"}},[_vm._v("Scheduler Updated At")]),_c('lth',{attrs:{"sort-by":"CreatedAt","width":"150"}},[_vm._v("Created At")]),_c('lth',{attrs:{"sort-by":"UpdatedAt","width":"150"}},[_vm._v("Updated At")])]},proxy:true},{key:"row",fn:function(ref){
    var item = ref.item;
return [_c('ltd',[_vm._v(_vm._s(item.Name))]),_c('ltd',[_vm._v(_vm._s(item.IsOnline))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.WsConnectedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.WsDisconnectedAt))]),_c('ltd',[_vm._v(_vm._s(item.Platform ? item.Platform.Name : ''))]),_c('ltd',[_vm._v(_vm._s(item.OsVersion))]),_c('ltd',[_vm._v(_vm._s(item.Description))]),_c('ltd',[_vm._v(_vm._s(item.IsActive))]),_c('ltd',[_vm._v(_vm._s(item.IsSelfDestructOn))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.SchedulerUpdatedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.UpdatedAt))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }