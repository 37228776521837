<template>
  <div>
    <v-dialog style="box-shadow: none;" max-width="577" v-model="menu">
      <template #activator="{on}">
        <v-text-field
            readonly
            hide-details
            :label="label"
            :value="formattedDate"
            v-on="on"
        />
      </template>
      <v-card>
        <v-layout class="app-date-time-picker">
          <v-date-picker v-model="date">
            <v-btn text color="primary" @click="time=null;date=null">Clear</v-btn>
          </v-date-picker>
          <v-time-picker format="24hr" v-model="time"/>
        </v-layout>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="menu = false" depressed>
            Close
          </v-btn>
          <v-btn @click="save" depressed color="primary">
            Apply
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {

  data() {
    return {
      menu: false,
      inputValue: this.value,

      formattedDate: null,

      date: null,
      time: null,
    }
  },

  props: {
    value: {
      default() {
        return null;
      }
    },
    label: String,
    format: {
      default() {
        // return 'YYYY-MM-DD HH:mm'
        return 'DD-MM-YYYY HH:mm'
        // return ""
      }
    }
  },

  watch: {
    value: function (value) {
      this.inputValue = value;
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init: function () {
      let dateTime = this.inputValue.split(' ');
      if (dateTime[0] && dateTime[1]) {
        this.date = dateTime[0];
        this.time = dateTime[1];
      }

      if (this.fullDate) {
        this.formattedDate = this.getFormattedDate;
      }
    },

    save: function () {
      this.$emit('input', this.fullDate);
      this.formattedDate = this.getFormattedDate;
      this.menu = false;
    }
  },

  computed: {
    fullDate: function () {
      let date = null;
      if (this.date && this.time) {
        if (this.time.length >= 4) {
          date = `${this.date} ${this.time}`;
        }
      }
      return date;
    },

    getFormattedDate: function () {
      if (this.fullDate) {
        return this.$moment(this.fullDate).format(this.format);
      }
      return null;
    }
  }

}
</script>
<style lang="scss">
.app-date-time-picker {
  .v-picker {
    border-radius: 0 !important;

    &:nth-child(2) {
      margin-left: -3px;
    }
  }

  .v-picker__title {
    height: 88px;
  }
}
</style>
