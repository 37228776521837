<template>
  <div>
    <v-app-bar dark class="app-bar" clipped-left app elevate-on-scroll>
      <TopBar/>
    </v-app-bar>
    <v-navigation-drawer
        app
        width="300"
        height="calc(100% - 110px)"
        class="app-navigation"
        clipped
        permanent
        :mini-variant.sync="mini"
    >
      <Navigation :mini.sync="mini" :items="items"/>
    </v-navigation-drawer>
    <v-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </v-main>
  </div>
</template>
<script>
import Navigation from "@/components/app/menu/Navigation";
import TopBar from "@/components/app/bar/TopBar";

export default {

  components: {
    TopBar,
    Navigation,
  },

  data: () => ({
    mini: false,
    items: [
      {title: 'Pagina principală', icon: 'grid_view', link: '/'},

      {
        title: 'Management',
        icon: 'ballot',
        items: [
          {title: 'Schedulers', link: 'worker_schedulers'},
          {title: 'Workers', link: 'workers'},
          {title: 'Mining Configs', link: 'worker_mining_configs'},
          {title: 'Mining App Versions', link: 'worker_mining_app_versions'},
          {title: 'Mining App Types', link: 'worker_mining_app_types'},
          {title: 'Platforms', link: 'worker_platforms'},
          {title: 'Worker App Versions', link: 'worker_app_versions'},
          {divider: true},
        ]
      },

      {
        title: 'Rapoarte',
        icon: 'tab',
        items: []
      },

      {title: 'Users', icon: 'manage_accounts', link: 'users'}
    ]
  }),
}
</script>
