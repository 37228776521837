<template>
  <div>
    <v-app-bar dark class="app-bar" clipped-left app elevate-on-scroll>
      <TopBar/>
    </v-app-bar>
    <v-navigation-drawer app
                         width="300"
                         height="calc(100% - 110px)"
                         class="app-navigation"
                         clipped
                         permanent
                         :mini-variant.sync="mini">
      <Navigation :mini.sync="mini" :items="items"/>
    </v-navigation-drawer>
    <v-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </v-main>
  </div>
</template>
<script>
import Navigation from "@/components/app/menu/Navigation";
import TopBar from "@/components/app/bar/TopBar";
export default {

  components: {
    TopBar,
    Navigation,
  },

  data: () => ({
    mini: false,
    items: [
      {title: 'Cheltuieli', icon: 'sell', link: '/'},
      {title: 'ONJN', icon: '', link: 'ongn'},
      {title: 'AML', icon: '', link: 'aml'},
      {title: 'Registru de casa', icon: '', link: 'registru'},
    ]
  }),
}
</script>
