<template>
  <v-card :disabled="disabled" flat tile :class="{'app-form': true}">
    <v-form :readonly="readonly" ref="form" v-model="valid" class="app-form-content">
      <template v-if="item">
        <v-card :outlined="!noBorder"
                :flat="flat"
                :class="{'mx-auto': true, 'mb-10': !noMarginBottom, 'mt-10': !noMarginTop && !isMobile}"
                :min-height="minHeight"
                :max-width="maxWidth">
          <v-app-bar flat>
            <v-toolbar-title>
              <slot name="title"/>
              {{ title }}
            </v-toolbar-title>
          </v-app-bar>

          <v-toolbar tag="aside" elevation="0" height="initial" class="app-form-actions">
            <Actions :hide-back-button="hideBackButton"
                     :hide-save-button="hideSaveButton"
                     :display-delete-button="displayDeleteButton"
                     :form-validation="formValidation"
                     @remove="remove"
                     @save="save"
                     v-if="!isMobile">
              <slot name="actions"></slot>
            </Actions>
          </v-toolbar>

          <v-bottom-navigation class="app-form-mobile-actions" v-if="isMobile" app fixed>
            <Actions :hide-back-button="hideBackButton"
                     :hide-save-button="hideSaveButton"
                     :display-delete-button="displayDeleteButton"
                     :form-validation="formValidation"
                     mobile
                     @remove="remove"
                     @save="save">
              <slot name="actions"></slot>
            </Actions>
          </v-bottom-navigation>

          <v-divider></v-divider>
          <slot name="default" :valid="valid" :item="item"></slot>
        </v-card>
      </template>
      <template v-else>
        <h1 class="pl-4 app-form-content-not-found">Not found</h1>
      </template>
    </v-form>
  </v-card>
</template>
<script>
import gql from "graphql-tag";
import Actions from "@/components/page/form/Actions";

export default {
  components: {Actions},

  data() {
    return {
      loading: false,
      item: this.value,
      valid: this.formValidation,
    }
  },

  props: {
    fullTitle: String,
    formValidation: Boolean,
    title: String,
    minHeight: String,
    noMarginTop: Boolean,
    noMarginBottom: Boolean,
    noBorder: Boolean,
    readonly: Boolean,
    flat: Boolean,
    reloadAfterSave: Boolean,
    disabled: Boolean,
    attachments: {
      default() {
        return []
      }
    },
    maxWidth: {
      default() {
        return '700'
      }
    },

    hideSaveButton: Boolean,
    hideDeleteButton: Boolean,
    hideBackButton: Boolean,

    routeParam: {
      default() {
        return 'id'
      }
    },

    queryIdField: {
      default() {
        return 'ID'
      }
    },
    queryType: String,
    queryParams: String,
    queryObjectParams: Object,
    mutationAdditionalObject: Object,
    queryFields: Array,
    mutationType: String,
    mutationFields: Array,
    mutationParams: String,
    mutationAdditional: String,
    deleteMutationType: String,
    deleteParams: String,
    deleteField: String,

    value: {
      default() {
        return {}
      }
    },
  },

  mounted() {
    if (this.getRouteParam) {
      this.get();
    }
  },

  watch: {
    item: function (value) {
      this.$emit('input', value);
    },

    value: function (value) {
      this.item = value;
    },
  },

  methods: {
    save: function (back = false) {
      try {

        this.$refs.form.validate();
        if (!this.valid && !this.formValidation) {
          return false;
        }
        this.togglePreloader(true);
        let mutationFields = ``;
        let mFields = this.getMutationFields;
        let queryFields = this.queryFields.join(' ');

        mutationFields = this.getGQLMutationFromObject(mFields, this.item);

        if (this.mutationParams) {
          mutationFields += this.mutationParams;
        }

        let mutationAdditional = ``;
        if (this.mutationAdditional) {
          mutationAdditional += this.mutationAdditional;
        }
        if (this.mutationAdditionalObject) {
          mutationAdditional = this.getGQLMutationFromObject(mutationAdditional, this.item);
        }


        let attachments = [];
        // console.log('this.attachments', this.attachments);

        if (this.attachments.length > 0) {
          this.attachments.map((attachment) => {
            // console.log(attachment)
            attachments.push({
              // File: new File(attachment),
              File: attachment,

              // In cazul in care se indica ID-ul, atunci se vor edita doar meta datele cum ar fi Description si CategoryID...
              // In cazul dat, nu trebuie de indicat File, acesta poate fi pur si simplu Null
              // ID: "",
              // Description: "",
              // CategoryID: "",
              // add other fields here
            });
          });
        }

        // console.log('other attachments', attachments);

        let mutationInput = `input: {${mutationFields}}`;
        let variables = {};
        let mutationVariables = ``;
        if (this.attachments.length > 0) {
          variables.attachments = attachments;
          mutationVariables += `($attachments: [FileAttachmentInput!])`;
          mutationAdditional += `, attachments: {
          DisableAutoDeletion: true,
          Attachments: $attachments
        }`
        }
        console.log(`mutation(${mutationVariables}) {
            ${this.mutationType} (${mutationInput} ${mutationAdditional}) {
                ${queryFields}
            }
        }`);

        return this.$apollo.mutate({
          mutation: gql`mutation${mutationVariables} {
            ${this.mutationType} (${mutationInput} ${mutationAdditional}) {
                ${queryFields}
            }
        }`,

          variables: variables
        }).then(res => {
          this.togglePreloader(false);
          this.notify('Salvat cu succes');
          if (!this.getRouteParam) {
            this.$router.back();
          }
          this.$nextTick(() => {
            if (this.reloadAfterSave) {
              this.get();
            }
            this.$emit('save');
          });
          this.item = res.data[this.mutationType];
          if (back) {
            let previousPage = this.getBreadCrumbs[this.getBreadCrumbs.length - 2];
            if (previousPage.href) {
              this.goTo(previousPage.href);
            } else {
              this.$router.back();
            }
          }
          return res;
        }).catch(res => {
          this.togglePreloader(false);
          this.notify('Eroare tehnică', 'error');
          return res;
        });
      } catch (res) {
        console.error(res);
      }

    },

    remove: function () {
      this.togglePreloader();
      let deleteParams = this.deleteParams;
      if (!this.deleteParams) {
        deleteParams = `${this.deleteField}: ["${this.getRouteParam}"]`;
      }
      this.$apollo.mutate({
        mutation: gql` mutation {
            ${this.deleteMutationType}(${deleteParams})
        }
        `
      }).then(res => {
        this.$router.back();
        this.notify('Șters cu succes');
        this.$emit('delete');
        this.togglePreloader(false);
      }).catch(res => {
        this.notify('Nu s-a putu șterge, eroare tehnică', 'error');
        this.togglePreloader(false);
      });
    },

    get: function () {
      let queryParams = this.queryParams || '';
      if (!this.queryParams && !this.queryObjectParams) {
        queryParams = `${this.queryIdField}: "${this.getRouteParam}"`
      }

      if (this.queryObjectParams) {
        queryParams += this.createGQLQueryFromObject(this.queryObjectParams, true);
      }

      this.$apollo.query({
        query: gql` query {
            ${this.queryType} (${queryParams}) {
                ${this.queryFields}
            }
         }
        `
      }).then(res => {
        this.item = res.data[this.queryType];
        this.$nextTick(() => {
          this.$emit('get');
        });
      });
    },
  },

  computed: {
    displayDeleteButton: function () {
      if (!this.deleteParams && !this.deleteField) {
        return false;
      }

      if (this.hideDeleteButton) {
        return false;
      }

      if (!this.routeParam) {
        return false;
      }

      return true;
    },

    getMutationFields: function () {
      return (this.mutationFields ? this.mutationFields : this.queryFields);
    },

    getRouteParam: function () {
      return this.$route.params[this.routeParam] || null;
    }
  }
}
</script>
<style lang="scss">
.app-form {

  &.v-card.theme--dark {
    background: #121212 !important;
  }

  .v-toolbar__title {
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &-content {

    .app-form-actions {
      position: fixed;
      border: thin solid var(--light-border-color);

      &.theme--dark {
        border: thin solid rgba(255, 255, 255, 0.12);
        background-color: #1E1E1E;
      }

      .v-toolbar__content {
        padding: 4px 8px;
      }
    }

    &-not-found {
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;
      text-align: center;
    }
  }
}

</style>
