<template>
  <div :class="{'app-view-header': true, 'border': displayBorder}">
    <div class="app-view-header-title">
      <slot name="title-suffix"></slot>
      <div v-if="displayIcon" class="app-icon-button">
        <v-icon>info</v-icon>
      </div>
      <div :class="{'main-title': true, 'ml-0': !displayIcon}">
        <slot name="title"></slot>
      </div>
      <slot name="title-prefix"></slot>
    </div>
    <div class="app-view-header-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    displayIcon: Boolean,
    displayBorder: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.app-view-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 15px 15px 15px 15px;
  width: 100%;

  &.border {
    border-bottom: 1px solid var(--light-border-color);
  }

  &-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .main-title {
      margin-left: 20px;
      font-weight: 500;
      font-size: 40px;
      line-height: 36px;
    }
  }

  &-actions {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .v-btn {
      margin-left: 8px;
    }
  }
}
</style>