<template>
  <v-flex>
    <v-file-input
        show-size
        :outlined="outlined"
        :solo="solo"
        v-model="files"
        :dense="dense"
        :multiple="multiple"
        :accept="accept"
        :rules="rules"
        :label="label"
    />
    <v-layout wrap class="mb-5">
      <v-chip
          close
          @click="openFile(item.ID)"
          @click:close="deleteFile(item.ID, i)"
          class="mr-2 mt-1"
          :key="item.ID" v-for="(item, i) in items"
          pill
      >
        <v-icon left>mdi-arrow-up-bold-box-outline</v-icon>
        <v-flex style="max-width: 100px; overflow: hidden; text-overflow: ellipsis">
          {{ item.Name }}
        </v-flex>
      </v-chip>
    </v-layout>
  </v-flex>
</template>
<script>
export default {

  data() {
    return {
      files: this.value,
    }
  },

  props: {
    outlined: Boolean,
    solo: Boolean,
    dense: Boolean,
    multiple: Boolean,
    label: String,
    accept: String,
    rules: Array,
    value: Array,
    items: {
      default() {
        return []
      }
    },
  },

  methods: {
    openFile: function (ID) {
      window.open(`${this.getUrl}storageDownload/${ID}`, '_blank');
    },

    deleteFile: function (ID, i) {
      let confirmation = confirm('Ești sigur?');
      if (!confirmation) {
        return false;
      }
      console.log("deleting file...!");
      axios.get(`${this.getUrl}attachmentDelete/${ID}`).then(res => {
        this.$delete(this.items, i);
      });
    }
  },

  watch: {
    files: function () {
      this.$emit('input', this.files);
    },

    value: function () {
      this.files = this.value;
    }
  },

  computed: {
    getUrl: function () {
      return process.env.VUE_APP_PROD_GRAPHQL_PRIVATE_HOST;
    }
  }

}
</script>