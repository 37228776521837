<template>
  <app-list
      title="Mining App Versions"
      :items-per-page="500"
      no-search-padding
      :search-by="[
        'Description',
        'Version',
      ]"
      show-filters
      export-query-type="workerMiningAppTypesExport"
      :query-fields="[
        'ID',
        'MiningAppTypeID',
        'MiningAppType {Name}',
        'Description',
        'Version',
        'Config',
        'FileID',
        'IsActive',

        'CreatedAt',
        'UpdatedAt',
      ]"
      delete-mutation-type="deleteWorkerMiningAppVersion"
      delete-mutation-field="ids"
      delete-by="ID"
      add-link="worker_mining_app_versions/add"
      query-type="workerMiningAppVersions"
      :row-click="onRowClick"
  >
    <template #head>
      <lth>Mining App Type</lth>
      <lth sort-by="Version">Version</lth>
      <lth sort-by="IsActive">Is Active</lth>
      <lth sort-by="Description" width="120">Description</lth>
      <lth sort-by="CreatedAt">Created At</lth>
      <lth sort-by="UpdatedAt" width="150">Updated At</lth>
    </template>
    <template #row="{item}">
      <ltd>{{ item.MiningAppType ? item.MiningAppType.Name : "" }}</ltd>
      <ltd>{{ item.Version }}</ltd>
      <ltd>{{ item.IsActive }}</ltd>
      <ltd>{{ item.Description }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  methods: {
    onRowClick: function (item) {
      this.goToPage(`worker_mining_app_versions/edit/${item.ID}`);
    },
  },

  data: () => ({}),

  mounted() {

  }
}
</script>