var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-form',{attrs:{"attachments":_vm.attachments,"query-fields":[
        'ID',
        'MiningAppTypeID',
        'Description',
        'IsActive',
        'Version',
        'Config',
        'FileID',
        'File {ID Name Extension}',

        'CreatedAt',
        'UpdatedAt' ],"mutation-fields":[
        'ID',
        'MiningAppTypeID',
        'Description',
        'IsActive',
        'Config' ],"delete-mutation-type":"deleteWorkerMiningAppVersion","route-param":"id","delete-field":"ids","mutation-type":"saveWorkerMiningAppVersion","query-type":"workerMiningAppVersion","title":("Mining App Version " + (_vm.item.ID))},on:{"save":_vm.save},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('app-form-section',[_c('app-form-section-block',{attrs:{"icon":"info"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-checkbox',{attrs:{"outlined":"","label":"Is Active"},model:{value:(_vm.item.IsActive),callback:function ($$v) {_vm.$set(_vm.item, "IsActive", $$v)},expression:"item.IsActive"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-autocomplete',{attrs:{"item-text":"Name","item-value":"ID","hide-details":"","items":_vm.workerMiningAppTypes,"outlined":"","label":"Worker Mining App Type"},on:{"change":_vm.getWorkerMiningAppTypes},model:{value:(_vm.item.MiningAppTypeID),callback:function ($$v) {_vm.$set(_vm.item, "MiningAppTypeID", $$v)},expression:"item.MiningAppTypeID"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"text","outlined":"","label":"Description"},model:{value:(_vm.item.Description),callback:function ($$v) {_vm.$set(_vm.item, "Description", _vm._n($$v))},expression:"item.Description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('app-file',{attrs:{"multiple":"","items":_vm.getCurrentFileItems,"accept":".zip","outlined":"","label":"Attach Mining App"},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-textarea',{attrs:{"hide-details":"","outlined":"","rows":"15","label":"Config"},model:{value:(_vm.item.Config),callback:function ($$v) {_vm.$set(_vm.item, "Config", $$v)},expression:"item.Config"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }