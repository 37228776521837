<template>
  <app-form
      :query-fields="[
          'ID',
          'Name',
          'Description',
          'PlatformID',
          'Platform {Name}',
          'OsVersion',
          'AuthToken',
          'SchedulerUpdatedAt',
          'IsActive',
          'IsSelfDestructOn',
          'CreatedAt',
          'UpdatedAt',
      ]"
      :mutation-fields="[
          'ID',
          'Name',
          'Description',
          'PlatformID',
          'OsVersion',
          'AuthToken',
          'IsActive',
          'IsSelfDestructOn',
      ]"
      v-model="item"
      delete-mutation-type="deleteWorker"
      route-param="id"
      delete-field="ids"
      mutation-type="saveWorker"
      query-type="worker"
      :title="`Worker ${item.Name} / ${item.ID}`"
  >
    <app-form-section>
      <app-form-section-block icon="info">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Name"
                hide-details
                type="text"
                outlined
                label="Name"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.Description"
                hide-details
                type="text"
                outlined
                label="Description"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.OsVersion"
                hide-details
                type="text"
                outlined
                label="Os Version"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
                v-model.number="item.AuthToken"
                hide-details
                type="text"
                outlined
                label="Auth Token"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.IsActive"
                hide-details
                outlined
                label="Is Active"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-checkbox
                v-model.number="item.IsSelfDestructOn"
                hide-details
                outlined
                label="Is Self Destruct On"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete
                item-text="Name"
                item-value="ID"
                @change="getWorkerPlatforms"
                hide-details
                :items="workerPlatforms"
                v-model="item.PlatformID"
                outlined
                label="Platform"
            />
          </v-col>
        </v-row>
      </app-form-section-block>
    </app-form-section>
  </app-form>
</template>
<script>
export default {
  data() {
    return {
      item: {
        PlatformID: '',
        IsActive: false,
        IsSelfDestructOn: false,
      },
      workerPlatforms: [],
    }
  },

  mounted() {
    this.getWorkerPlatforms();
  },

  methods: {
    // getWorkerPlatforms: function (value) {
    getWorkerPlatforms: function () {
      // let query = this.getSingleQuery('VendorID', value);

      this.getGQLItems('workerPlatforms', [
        'Name',
        'ID',
      ]).then(res => {
        this.workerPlatforms = res.data.workerPlatforms.Items;
      });

      /*let query = this.getSingleQuery('VendorID', value);

      this.getGQLItems('workerPlatforms', [
        'Name',
        'ID',
      ], query).then(res => {
        this.workerPlatforms = res.data.workerPlatforms.Items;
      });*/
    },
  }
}
</script>