import gql from "graphql-tag";

export default {

    methods: {

        getGQLMutationFromObject: function (fields, obj) {
            let mutationFields = ``;
            obj = this.clone(obj);
            fields.forEach((field, i) => {
                let mutationNestedFields = ``;
                mutationNestedFields += this.getGqlValueFromBrackets(field, obj);
                let value = obj[field];
                let type = typeof value;
                value = this.getGQLValue(value, type);
                let comma = i !== fields.length - 1 ? ',' : '';

                if (mutationNestedFields) {
                    mutationFields += `${mutationNestedFields},`;
                } else {
                    mutationFields += `${field}:${value}${comma}`;
                }
            });

            return mutationFields;
        },

        getGqlValueFromBrackets: function (field, obj) {
            if (field.indexOf('{') >= 0 && field.indexOf('}') >= 0) {
                let firstField = field.split(' ')[0];
                let subObj = obj[firstField];
                if (subObj) {
                    field.replace('{', '');
                    field.replace('}', '');
                    let subFields = field.split(' ');
                    delete subFields[0];

                    let subObjectValues = ``;
                    let newSubFields = ``;

                    subFields.forEach((subField, i) => {

                        newSubFields += this.getGqlValueFromBrackets(subField, subObj);
                        subField = subField.replace(firstField, '');
                        subField = subField.replace(' ', '');
                        subField = subField.replace(',', '');
                        subField = subField.replace('{', '');
                        subField = subField.replace('}', '');

                        console.log('subObj', subObj);
                        console.log('subField', subField);

                        let value = subObj[subField];
                        let type = typeof value;
                        value = this.getGQLValue(value, type);
                        let comma = i !== subFields.length - 1 ? ',' : '';

                        if (newSubFields) {
                            subObjectValues += `${subField}:${newSubFields}${comma}`;
                        } else {
                            console.log(`${subField}:${value}${comma}`);
                            subObjectValues += `${subField}:${value}${comma}`;
                        }

                    });
                    return `${firstField}: {${subObjectValues}}`
                }
            }
            return '';
        },

        getFilterGQLMultipleValues: function (value) {
            let values = ``;
            value.forEach((val, i) => {
                let comma = i !== value - 1 ? ',' : '';
                values += `"${val}"${comma}`;
            });
            return values;
        },

        getGQLValue: function (value, type) {
            // let hasOnlyNumbers = this.isNumber(value);
            // if (hasOnlyNumbers) {
            //     type = 'number';
            // }
            if (value == 'null') {
                value = '';
            }

            if (type === 'undefined') {
                type = 'string';
            }

            if (type === 'string') {
                if (value) {
                    value = value.replace(/\"/g, '\\"');
                }
                let match = /\r|\n/.exec(value);
                if (match) {
                    value = `"""${value}"""`;
                } else {
                    value = `"${value || ''}"`;
                }
            } else if (type === 'boolean') {
                value = `${value || false}`;
            } else if (type === 'number') {
                value = `${value || 0}`;
            }
            return value;
        },

        createGQLQueryFromObject(item, keysAsParams = true) {
            // Check if object
            // Check if array
            // Check if int
            // Check if boolean
            // Check if
            // if it's object, them we should loop through it and convert everything!
            let q = "";
            let type = typeof item;
            if (Array.isArray(item)) {
                q = JSON.stringify(item);
            } else if (type === "object") {
                for (const [key, value] of Object.entries(item)) {
                    if (value) {
                        let _key = "";
                        if (keysAsParams) {
                            _key = key;
                        } else {
                            _key = '"' + key + '"';
                        }
                        q += _key + ":" + this.createGQLQueryFromObject(value) + " \n";
                    }
                }
            } else if (type === "string") {
                q = `"${item}"`;
            } else if (type === "boolean") {
                if (item) {
                    q = "true";
                } else {
                    q = "false";
                }
            } else if (type === "number") {
                q = item;
            }
            return q;
        },

        getSingleQuery: function (field, value, type = 'Eq') {
            return `
              RootConditions:{
                Conditions:[
                  {
                    ${type}: {
                      Name: "${field}",
                      Value: "${value}"
                    }
                  }
                ]
              }
          `;
        },


        bootstrap: function () {
            return this.$apollo.query({
                query: gql`query {
                    bootstrap {
                        DefaultRequestPath
                        DefaultLang
                        Translations
                    }
                }
                `,

                client: 'a'
            }).then(res => {
                this.$store.commit('setBootstrap', res.data.bootstrap);
                return res;
            });
        },

        getGQLItems: function (queryType, queryFields = [], query = ``) {
            let defaultQuery = `
            (
                input: {
                    NrOfItems: 10000,
                    ${query}
                }
            )
            `;

            let fields = queryFields.join(' ');

            return this.$apollo.query({
                fetchPolicy: 'no-cache',
                query: gql`query {
                    ${queryType} ${defaultQuery} {
                    Items {
                        ${fields}
                    }
                }
                }`
            }).then(res => {
                return res;
            });
        },
    },

    computed: {}

}