<template>
  <div>
    <div ref="scrollbar" class="app-list-scrollbar"></div>
    <v-scroll-y-transition>
    <div v-show="getScrollPos > 10"
         @click="toTop"
         v-ripple="{class: 'primary--text'}"
         ref="toTop"
         :class="{'app-list-to-top': true, 'theme--dark': this.getTheme}">
      <v-icon>north</v-icon>
    </div>
    </v-scroll-y-transition>
  </div>
</template>
<script>
export default {

  data() {
    return {
      el: null,
      scrollPercentage: 0,
    }
  },

  mounted() {
    this.el = $(this.$parent.$el).find('.app-list').find('.v-data-table__wrapper');
    this.el.on('scroll', (e) => {
      let _el = e.target;
      let blockHeight = _el.scrollHeight - _el.offsetHeight;
      let scrollPos = _el.scrollTop;
      let scrollPercentage = scrollPos * 100 / blockHeight;
      this.scrollPercentage = scrollPercentage;
      $(this.$refs.scrollbar).width(`${scrollPercentage}%`);
    });

    if (this.el.find('tfoot').find('tr').length > 0) {
      if (this.$parent.$parent.actions.dense) {
        $(this.$refs.toTop).css('bottom', '45px');
      } else {
        $(this.$refs.toTop).css('bottom', '65px');
      }
    }
  },

  methods: {
    toTop: function() {
      this.el.scrollTop(0);
    }
  },

  computed: {
    getScrollPos: function() {
      return this.scrollPercentage;
    }
  }

}
</script>
<style lang="scss" scoped>
.app-list-to-top {
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  padding: 10px;
  backdrop-filter: blur(2px);
  cursor: pointer;
  background-color: #fff;

  &:not(.theme--dark) {
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
  }

  &.theme--dark {
    background-color: #272727;
  }
}

.app-list-scrollbar {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--v-primary-base);
  z-index: 2;
}
</style>