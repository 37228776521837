<template>
  <app-list
      title="Mining App Configs"
      :items-per-page="500"
      no-search-padding
      :search-by="[
        'Name',
        'Description',
      ]"
      show-filters
      export-query-type="workerMiningConfigsExport"
      :query-fields="[
        'ID',
        'Name',
        'Description',

        'MiningAppTypeID',
        'MiningAppType {Name}',
        'MiningAppVersionID',
        'MiningAppVersion {Description}',

        'Config',

        'CreatedAt',
        'UpdatedAt',
      ]"
      delete-mutation-type="deleteWorkerMiningConfig"
      delete-mutation-field="ids"
      delete-by="ID"
      add-link="worker_mining_configs/add"
      query-type="workerMiningConfigs"
      :row-click="onRowClick"
  >
    <template #head>
      <lth sort-by="Name" width="120">Name</lth>
      <lth sort-by="Description" width="120">Description</lth>
      <lth>Mining App Type</lth>
      <lth>Mining App Version</lth>
      <lth sort-by="CreatedAt">Created At</lth>
      <lth sort-by="UpdatedAt" width="150">Updated At</lth>
    </template>
    <template #row="{item}">
      <ltd>{{ item.Name }}</ltd>
      <ltd>{{ item.Description }}</ltd>
      <ltd>{{ item.MiningAppType ? item.MiningAppType.Name : "" }}</ltd>
      <ltd>{{ item.MiningAppVersion ? item.MiningAppVersion.Description : "" }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  methods: {
    onRowClick: function (item) {
      this.goToPage(`worker_mining_configs/edit/${item.ID}`);
    },
  },

  data: () => ({}),

  mounted() {

  }
}
</script>