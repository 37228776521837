<template>
  <app-list
      title="Worker Schedulers"
      :items-per-page="500"
      no-search-padding
      :search-by="[
        'Name',
      ]"
      show-filters
      export-query-type="workerPlatformsExport"
      :query-fields="[
        'ID',
        'WorkerID',
        'Worker {Name}',

        'MiningConfigID',
        'MiningConfig {Name}',

        'From',
        'To',

        'AllDay',
        'OnAlways',
        'OnMonday',
        'OnTuesday',
        'OnWednesday',
        'OnThursday',
        'OnFriday',
        'OnSaturday',
        'OnSunday',

        'CreatedAt',
        'UpdatedAt',
      ]"
      delete-mutation-type="deleteWorkerScheduler"
      delete-mutation-field="ids"
      delete-by="ID"
      add-link="worker_schedulers/add"
      query-type="workerSchedulers"
      :row-click="onRowClick"
  >
    <template #head>
      <lth sort-by="ID">ID</lth>
      <lth>Worker Name</lth>
      <lth sort-by="From">From (UTC)</lth>
      <lth sort-by="To">To (UTC)</lth>
      <lth>Mining Config Name</lth>

      <lth sort-by="CreatedAt">Created At</lth>
      <lth sort-by="UpdatedAt" width="150">Updated At</lth>
    </template>
    <template #row="{item}">
      <ltd>{{ item.ID }}</ltd>
      <ltd>{{ item.Worker ? item.Worker.Name : "" }}</ltd>
      <ltd>{{ item.From ? item.From : "" }}</ltd>
      <ltd>{{ item.To ? item.To : "" }}</ltd>
      <ltd>{{ item.MiningConfig ? item.MiningConfig.Name : "" }}</ltd>

      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {
  methods: {
    onRowClick: function (item) {
      this.goToPage(`worker_schedulers/edit/${item.ID}`);
    },
  },


  data: () => ({}),

  mounted() {

  }
}
</script>