var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"title":"Worker Schedulers","items-per-page":500,"no-search-padding":"","search-by":[
      'Name' ],"show-filters":"","export-query-type":"workerPlatformsExport","query-fields":[
      'ID',
      'WorkerID',
      'Worker {Name}',

      'MiningConfigID',
      'MiningConfig {Name}',

      'From',
      'To',

      'AllDay',
      'OnAlways',
      'OnMonday',
      'OnTuesday',
      'OnWednesday',
      'OnThursday',
      'OnFriday',
      'OnSaturday',
      'OnSunday',

      'CreatedAt',
      'UpdatedAt' ],"delete-mutation-type":"deleteWorkerScheduler","delete-mutation-field":"ids","delete-by":"ID","add-link":"worker_schedulers/add","query-type":"workerSchedulers","row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('lth',{attrs:{"sort-by":"ID"}},[_vm._v("ID")]),_c('lth',[_vm._v("Worker Name")]),_c('lth',{attrs:{"sort-by":"From"}},[_vm._v("From (UTC)")]),_c('lth',{attrs:{"sort-by":"To"}},[_vm._v("To (UTC)")]),_c('lth',[_vm._v("Mining Config Name")]),_c('lth',{attrs:{"sort-by":"CreatedAt"}},[_vm._v("Created At")]),_c('lth',{attrs:{"sort-by":"UpdatedAt","width":"150"}},[_vm._v("Updated At")])]},proxy:true},{key:"row",fn:function(ref){
    var item = ref.item;
return [_c('ltd',[_vm._v(_vm._s(item.ID))]),_c('ltd',[_vm._v(_vm._s(item.Worker ? item.Worker.Name : ""))]),_c('ltd',[_vm._v(_vm._s(item.From ? item.From : ""))]),_c('ltd',[_vm._v(_vm._s(item.To ? item.To : ""))]),_c('ltd',[_vm._v(_vm._s(item.MiningConfig ? item.MiningConfig.Name : ""))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.CreatedAt))]),_c('ltd',{attrs:{"date":""}},[_vm._v(_vm._s(item.UpdatedAt))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }