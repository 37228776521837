var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'d-flex': true, 'wrap': _vm.mobile, 'flex-column': !_vm.mobile}},[_c('v-tooltip',{attrs:{"left":!_vm.mobile,"top":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.displayDeleteButton)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('remove')}}},on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Șterge")])]),_c('v-tooltip',{attrs:{"left":!_vm.mobile,"top":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.hideBackButton)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},on),[_c('v-icon',[_vm._v("arrow_back")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Înapoi")])]),_c('v-tooltip',{attrs:{"left":!_vm.mobile,"top":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.hideSaveButton)?_c('v-btn',_vm._g({attrs:{"loading":_vm.getPreloader,"icon":""},on:{"click":function($event){return _vm.$emit('save', true)}}},on),[_c('v-icon',[_vm._v("check")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Salvează și Închide")])]),_c('v-tooltip',{attrs:{"left":!_vm.mobile,"top":_vm.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.hideSaveButton)?_c('v-btn',_vm._g({attrs:{"loading":_vm.getPreloader,"icon":""},on:{"click":function($event){return _vm.$emit('save', false)}}},on),[_c('v-icon',[_vm._v("save")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Salvează")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }