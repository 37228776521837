<template>
  <app-list
      title="Workers"
      :items-per-page="500"
      no-search-padding
      :search-by="[
          'Name',
          'AuthToken',
      ]"
      show-filters
      export-query-type="workersExport"
      :query-fields="[
          'ID',
          'Name',
          'Description',
          'PlatformID',
          'Platform {Name}',
          'OsVersion',
          'AuthToken',
          'SchedulerUpdatedAt',
          'IsActive',
          'IsSelfDestructOn',
          'CreatedAt',
          'UpdatedAt',

          'IsOnline',
          'WsConnectedAt',
          'WsDisconnectedAt',
      ]"
      delete-mutation-type="deleteWorker"
      delete-mutation-field="ids"
      delete-by="ID"
      add-link="workers/add"
      query-type="workers"
      :row-click="onRowClick"
  >

    <template #head>
      <lth sort-by="Name" width="120">Name</lth>
      <lth sort-by="IsOnline">Is Online</lth>
      <lth sort-by="WsConnectedAt">Connected At</lth>
      <lth sort-by="WsDisconnectedAt">Disconnected At</lth>
      <lth sort-by="PlatformID" width="120">Platform</lth>
      <lth sort-by="OsVersion">Os Version</lth>
      <lth sort-by="Description">Description</lth>
      <lth sort-by="IsActive">Is Active</lth>
      <lth sort-by="IsSelfDestructOn">Is Self Destruct On</lth>
      <lth sort-by="SchedulerUpdatedAt" width="170">Scheduler Updated At</lth>
      <lth sort-by="CreatedAt" width="150">Created At</lth>
      <lth sort-by="UpdatedAt" width="150">Updated At</lth>
    </template>
    <template #row="{item}">
      <ltd>{{ item.Name }}</ltd>
      <ltd>{{ item.IsOnline }}</ltd>
      <ltd date>{{ item.WsConnectedAt }}</ltd>
      <ltd date>{{ item.WsDisconnectedAt }}</ltd>
      <ltd>{{ item.Platform ? item.Platform.Name : '' }}</ltd>
      <ltd>{{ item.OsVersion }}</ltd>
      <ltd>{{ item.Description }}</ltd>
      <ltd>{{ item.IsActive }}</ltd>
      <ltd>{{ item.IsSelfDestructOn }}</ltd>
      <ltd date>{{ item.SchedulerUpdatedAt }}</ltd>
      <ltd date>{{ item.CreatedAt }}</ltd>
      <ltd date>{{ item.UpdatedAt }}</ltd>
    </template>
  </app-list>
</template>
<script>
export default {

  methods: {
    onRowClick: function (item) {
      this.goToPage(`workers/edit/${item.ID}`);
    },
  },

  data: () => ({}),

  mounted() {

  }
}
</script>