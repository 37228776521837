var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-end":""}},[(_vm.searchBy)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'v-btn--active': _vm.buttons.filters, 'mr-1': true},attrs:{"icon":"","small":_vm.isMobile,"value":"search"},on:{"click":function($event){return _vm.handleQuickSearch(!_vm.buttons.filters)}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,false,2435362159)},[_c('span',[_vm._v("Caută")])]):_vm._e(),(_vm.exportQueryType)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","loading":_vm.loading.export,"small":_vm.isMobile,"value":"search"},on:{"click":_vm.handleExport}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,false,1236707638)},[_c('span',[_vm._v("Exportă")])]):_vm._e(),(_vm.buttons.selectMode && _vm.deleteBy)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","value":"deleteSelected","small":_vm.isMobile},on:{"click":_vm.handleDeleteSelected}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1)]}}],null,false,2237566456)},[_c('span',[_vm._v("Șterge selectate")])]):_vm._e(),(_vm.deleteBy || _vm.selectable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'v-btn--active': _vm.buttons.selectMode, 'mr-1': true},attrs:{"icon":"","small":_vm.isMobile,"value":"select"},on:{"click":function($event){return _vm.handleSelect(!_vm.buttons.selectMode)}}},on),[_c('v-icon',[_vm._v("checklist")])],1)]}}],null,false,872378112)},[_c('span',[_vm._v("Selectează datele")])]):_vm._e(),(_vm.options && _vm.options.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":_vm.isMobile,"value":"options"}},Object.assign({}, onTooltip, onMenu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v("Opțiuni")])])]}}],null,false,2069058467)},[_c('v-list',_vm._l((_vm.options),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":item.action}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'v-btn--active': _vm.buttons.dense, 'mr-1': true},attrs:{"icon":"","small":_vm.isMobile,"value":"dense"},on:{"click":function($event){return _vm.handleDense(!_vm.buttons.dense)}}},on),[_c('v-icon',[_vm._v("reorder")])],1)]}}])},[_c('span',[_vm._v("Listă îngustă")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.hideFilters)?_c('v-btn',_vm._g({class:{'v-btn--active': _vm.buttons.filters, 'mr-1': true},attrs:{"icon":"","small":_vm.isMobile,"value":"filters"},on:{"click":function($event){return _vm.handleFilters(!_vm.buttons.filters)}}},on),[_c('v-icon',[_vm._v("filter_list")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Filtre")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.addLink)?_c('v-btn',_vm._g({staticClass:"v-btn--active",attrs:{"icon":"","color":"primary","small":_vm.isMobile,"value":"add","exact":"","to":_vm.addLink}},on),[_c('v-icon',[_vm._v("add")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Adaugă")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }