var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-list-section"},[_c('v-card',{staticClass:"app-list-section-header",attrs:{"outlined":"","flat":""}},[_c('v-app-bar',{attrs:{"flat":""}},[_c('div',{staticClass:"text-h7 text-lg-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('ListActions',{ref:"listActions",attrs:{"add-link":_vm.addLink,"selectable":_vm.selectable,"delete-by":_vm.deleteBy,"export-query-type":_vm.exportQueryType,"options":_vm.options,"search-by":_vm.searchBy,"hide-filters":_vm.hideFilters},on:{"action":_vm.handleAction,"focusSearch":_vm.focusSearch,"delete":_vm.deleteSelected}},[_vm._t("actions")],2)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.actions.filters),expression:"actions.filters"}]},[_c('v-layout',{class:((_vm.hideFilters ? '' : 'pt-3') + " app-list-row-filters " + (_vm.noFiltersBottomPadding || _vm.hideFilters ? 'pb-0' : 'pb-3') + " " + (_vm.denseFilters ? 'dense-filters' : '')),attrs:{"wrap":""}},[(_vm.searchBy)?_c('v-col',{class:{'pt-0': _vm.noSearchPadding, 'pb-0': _vm.noSearchBottomPadding},attrs:{"cols":"12","md":_vm.largeSearch ? 6 : 3,"lg":_vm.largeSearch ? 5 : 3,"xl":_vm.largeSearch ? 2 : 1}},[_c('v-text-field',{ref:"search",attrs:{"filled":"","outlined":"","dense":_vm.actions.dense,"label":"Caută","single-line":"","clearable":"","hide-details":"","prepend-inner-icon":"search"},on:{"click:clear":function($event){return _vm.search(null)},"change":_vm.search}})],1):_vm._e(),_vm._t("filters")],2)],1),_c('v-card',{staticClass:"app-list-section-content",attrs:{"outlined":"","flat":""}},[_c('ListScrollbar'),_c('v-simple-table',{class:{
    'app-list': true,
    'selectable': _vm.actions.selectMode
  },attrs:{"height":_vm.listHeight,"fixed-header":"","dense":_vm.actions.dense},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.actions.selectMode)?_c('th',{attrs:{"width":"40"}},[(!_vm.singleSelect)?_c('v-checkbox',{staticClass:"mt-0 mb-0",attrs:{"hide-details":""},on:{"click":_vm.selectAll},model:{value:(_vm.actions.selectAll),callback:function ($$v) {_vm.$set(_vm.actions, "selectAll", $$v)},expression:"actions.selectAll"}}):_vm._e()],1):_vm._e(),_vm._t("head")],2),_vm._t("bottom-head")],2),_c('tbody',[_vm._l((_vm.items),function(item,i){return _c('tr',{key:item[_vm.itemKey],class:[
              _vm.isSelected(item) ? 'is-selected' : '',
              !_vm.rowClass || _vm.rowClass(item),
              _vm.rowClick ? 'cursor-pointer' : ''
            ],on:{"click":function($event){return _vm.select(item)}}},[(_vm.actions.selectMode)?_c('td',[_c('v-checkbox',{staticClass:"mt-0 mb-0",attrs:{"input-value":_vm.isSelected(item),"hide-details":""}})],1):_vm._e(),_vm._t("row",null,{"index":i,"item":item})],2)}),(_vm.items.length === 0)?_c('tr',[_c('td',{staticClass:"no-data",attrs:{"colspan":"10"}},[_vm._v("Nu au fost găsite date")])]):_vm._e()],2),(_vm.rerender)?_c('tfoot',[_vm._t("footer",null,{"items":_vm.items})],2):_vm._e()]},proxy:true}],null,true)})],1),_c('v-layout',{staticClass:"app-list-pagination",attrs:{"justify-end":!_vm.isMobile,"justify-center":_vm.isMobile,"align-center":"","wrap":""}},[_vm._v(" Date per pagină: "),_c('div',{staticClass:"per-page"},[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":[10, 20, 50, 100, 500, 1000],"hide-details":""},on:{"change":function($event){return _vm.handleListChange('nrOfItems')}},model:{value:(_vm.list.nrOfItems),callback:function ($$v) {_vm.$set(_vm.list, "nrOfItems", $$v)},expression:"list.nrOfItems"}})],1),_vm._v(" 1-"+_vm._s(_vm.list.nrOfItems)+" din "+_vm._s(_vm.list.total)+" "),_c('v-pagination',{attrs:{"total-visible":10,"length":_vm.list.lastPage},on:{"input":_vm.handleListChange},model:{value:(_vm.list.page),callback:function ($$v) {_vm.$set(_vm.list, "page", $$v)},expression:"list.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }